





























































































.discovery-nav
  .q-tab
    padding: 0 8px
  .q-icon
    font-size: 22px
